import { Heading } from "src/components/ui/Text";
import Input from "src/components/ui/Input";
import { useState } from "react";
import { PrimaryButton } from "src/components/ui/Buttons";
import { isEmail } from "validator";
import { mutate } from "swr";

function validateOTP(code) {
  return code && code.trim().length === 6 ? null : "Not a valid login code";
}

function EmailForm({ setData }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function submitHandler(e) {
    e.preventDefault();
    const error = isEmail(email || "") ? null : "Email is not valid";
    setError(error);
    if (error) {
      return;
    }
    setLoading(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
          action: "submit",
        })
        .then(function (token) {
          fetch("/api/generate-otp", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, token }),
          })
            .then((res) => {
              if (res.ok) {
                setData({ email });
              } else {
                return res.text();
              }
            })
            .then((serverError) => {
              setError(serverError);
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
            });
        });
    });
  }

  return (
    <form onSubmit={submitHandler}>
      <div>
        <p className="mb-2">Enter the email and we'll send you a login code.</p>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full my-2"
          error={error}
        />
      </div>
      <div className="flex flex-col justify-end h-16 md:h-20 my-2 md:my-4">
        <PrimaryButton md={true} type="submit" disabled={loading}>
          {loading ? "Sending Login Code" : "Get Login Code"}
        </PrimaryButton>
      </div>
    </form>
  );
}

function OTPForm({ data, onSuccess }) {
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState(null);
  const [loading, setLoading] = useState(null);

  function submitHandler(e) {
    e.preventDefault();
    const error = validateOTP(otp);
    setOTPError(error);
    if (error) {
      return;
    }
    setLoading(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
          action: "submit",
        })
        .then(function (token) {
          fetch("/api/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...data, otp, token }),
          })
            .then((res) => {
              if (res.ok) {
                mutate(
                  "/api/user",
                  fetch("/api/user")
                    .then((res) => res.json())
                    .then((user) => {
                      onSuccess(user);
                      return user;
                    })
                );
              } else {
                return res.text();
              }
            })
            .then((serverError) => {
              setOTPError(serverError);
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
            });
        });
    });
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="">
        <p>We've sent an 6 digit login code to your Email</p>
        <Input
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
          placeholder="Enter login code"
          className="w-full my-2"
          error={otpError}
        />
      </div>
      <div className="flex flex-col justify-end h-16 md:h-20 my-2 md:my-4">
        <PrimaryButton md={true} type="submit" disabled={loading}>
          {loading ? "Logging In" : "Login"}
        </PrimaryButton>
      </div>
    </form>
  );
}

export default function Login({ onSuccess }) {
  const [data, setData] = useState(null);
  return (
    <div>
      <Heading className="text-center my-4 md:my-8">Login</Heading>
      {data ? (
        <OTPForm data={data} onSuccess={onSuccess} />
      ) : (
        <EmailForm setData={setData} />
      )}
    </div>
  );
}
