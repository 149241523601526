import classNames from "classnames";

export default function Input({
  type,
  value,
  onChange,
  placeholder,
  className,
  error,
}) {
  const inputClass = classNames(
    "px-4 py-2 mb-0 rounded border-2 border-gray-400 focus:border-black ",
    "outline-none",
    "text-md md:text-xl",
    className
  );
  return (
    <div className="h-20">
      <input
        type={type}
        className={inputClass}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <div className="text-red text-sm pl-2">{error}</div>}
    </div>
  );
}
